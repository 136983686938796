<script setup lang="ts">
const {t} = useI18n();

useSeoMeta({
  title: () => t('errors.Page not found'),
  ogTitle: () => t('errors.Page not found'),
  description: () => t('errors.Page not found'),
  ogImage: () => '/images/barkar-with-bg.jpg',
  twitterImage: () => '/images/barkar-with-bg.jpg',
})
</script>

<template>
    <div>
        <div class="w-full h-[calc(100vh)] bg-gradient-to-t from-secondary from-10% via-white/60 via-50% to-secondary/50 to-90%">
            <div class="container m-auto flex flex-center h-full flex-col">
                <ElementsTitle error :title="404 + ' ' + $t('errors.Page not found')" class="mb-10"/>
              <NuxtLinkLocale to="/">
                <ElementsButton >
                    {{ $t('text.to index') }}
                </ElementsButton>
              </NuxtLinkLocale>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>